#saving_goals_chart {
  padding: 7px;
  border-radius: 11px;
  background-color: white;
}
.highcharts-credits {
  display: none;
}

.slick-track {
  display: flex;
}

.slick-prev::before,
.slick-next::before {
  font-size: 35px;
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  color: #545cd8;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev {
  left: 0;
}

.slick-prev:before {
  content: "\E686";
}

.slick-slider-hover .slick-arrow {
  opacity: 0;
  background: #545cd8;
  color: #fff !important;
  display: none;
  visibility: hidden;
}

.slick-slider-hover .slick-prev {
  left: -30px;

  display: none;
  visibility: hidden;
}

.slick-slider-hover .slick-next {
  right: -30px;

  display: none;
  visibility: hidden;
}

.slick-slider-hover:hover .slick-arrow {
  opacity: 1;

  display: none;
  visibility: hidden;
}

.slick-prev {
  left: 0;
}

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 40px;
  width: 40px;
  cursor: pointer;
  color: #6c757d;
  top: 50%;
  -webkit-transform: translate(0, -80%);
  transform: translate(0, -80%);
  padding: 0;
  border: none;
  outline: none;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-family: "Pe-icon-7-stroke";
  border-radius: 50px;
  background: #fff;
  z-index: 6;
  overflow: hidden;
}

.slick-prev:before {
  content: "<";
}

.slick-prev::before,
.slick-next::before {
  font-size: 35px;
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.slick-next:before {
  content: ">";
}

.slick-prev::before,
.slick-next::before {
  font-size: 35px;
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-dots {
  list-style: none;
  display: none;
  text-align: center;
  padding: 0;
  margin: 0 0 0;
  width: 100%;
  /*margin-top: -135px;*/
  position: absolute;
}

.slick-dots li.slick-active {
  -webkit-transform: scale(1.2);
  transform: scale(0.8) !important;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 1px;
  cursor: pointer;
  -webkit-transform: scale(0.5) !important;
  transform: scale(0.5);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 14px;
  width: 14px;
  background: #3a5fac;
  cursor: pointer;
  position: relative;
  border-radius: 20px;
  color: transparent;
}

.slick-dots li button::before {
  content: "";
  position: absolute;
  background: #fff;
  left: 50%;
  top: 50%;
  margin: -4px 0 0 -4px;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

.slick-next {
  right: 0px;
}

.slick-arrow {
  color: white;
  background-color: #184178;
  font-weight: 900;
  visibility: hidden !important;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  color: white !important;
}

.carousel-indicators li {
  background-color: rgb(105, 131, 167);
}

.carousel-indicators .active {
  background-color: #1b447a;
}

.timeline-Header {
  display: none !important;
}
.timeline-Footer {
  display: none !important;
}
.timeline-InformationCircle-widgetParent {
  display: none !important;
}

#name {
  display: none;
}

@media (min-width: 800px) {
  /* .float_right {
          float:right;
        } */
}
@media (max-width: 450px) {
  .compare-top-img {
    display: none;
  }
}

.section.section-compare-saving .card-saving {
  background: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  text-align: center;
  min-height: 100%;
}
.section.section-compare-saving .card-saving .card-s-txt h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #3a5fac;
  /* font-family: 'RobotoBold'; */
}
.section.section-compare-saving .card-saving .card-s-txt p {
  font-size: 16px;
  color: #6d6e6e;
  line-height: 24px;
  margin: 0;
}
.section.section-compare-goal {
  padding-bottom: 50px;
  padding-top: 50px;
}
.section.section-compare-goal .cmp-heading {
  text-transform: uppercase;
  /* font-family: 'RobotoBold'; */
  font-size: 32px;
}
.section.section-compare-goal .goal-tabs-wrapper {
  padding: 50px 0;
}
.section.section-compare-goal .goal-tabs-wrapper .goal-tabs-inner {
  background: #fff;
  border-radius: 10px;
  padding: 20px 15px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  text-align: center;
}
.section.section-compare-goal .calculate-btn .btn {
  width: 100%;
  max-width: 300px;
  margin-top: 17px;
}
.btn.btn-primary {
  background: #3a5fac;
  color: #fff;
  border: 1px solid #3a5fac;
}
.section.section-compare-goal .goal-tabs-wrapper .tab-content .tab-pane p {
  font-size: 18px;
  margin: 0;
  color: #3a5fac;
}
.section.section-compare-goal .goal-tabs-wrapper .nav-tabs .nav-item .nav-link {
  border: 0;
  color: #3a5fac;
  font-size: 16px;
  text-align: center;
  display: block;
  border-radius: 25px;
  height: 50px;
  line-height: 35px;
}
.section.section-compare-goal
  .goal-tabs-wrapper
  .nav-tabs
  .nav-item
  .nav-link.active {
  background: #3a5fac;
  color: #fff;
}
.section.section-compare-goal .goal-tabs-wrapper .nav-tabs {
  padding: 0;
  margin: 0 auto;
  border: 1px solid #3a5fac;
  height: 50px;
  border-radius: 25px;
  max-width: 800px;
  /* min-width: 466px !important; */
}
.section.section-compare-goal .goal-tabs-wrapper .nav-tabs .nav-item {
  float: left;
  width: 25%;
  border: 0;
}
.section.section-compare-top .compare-top-img {
  text-align: center;
}
.section.section-compare-top .compare-top-txt h2 {
  color: #3a5fac;
  margin: 0;
  /* font-family: 'RobotoBold'; */
}
.section.section-compare-top .compare-top-txt h6 {
  color: #3a5fac;
  margin: 0 0 5px;
  font-size: 20px;
}

.goal-box:hover {
  background-color: #e0f1fc ;
}
.goal-box {
  background-color: #e0f1fc ;
}
.goal-box .goal-box-info p {
  margin: 0;
  color: #3a5dac;
  font-size: 12px;
}
.goal-box .goal-box-info h4 {
  font-size: 20px;
  margin: 0 0 2px;
  color: #3a5dac;
  font-family: "HelveticaNeuBold";
}
.img-subMenue {
  height: 20px;
}
.subMenu-span {
  top: -2px !important;
  left: 35px !important;
}
@media (max-width: 767px) {
  .portfolioButton {
    width: 100% !important;
  }
}
.choose-goal-box {
  padding: 10px;
  height: 100px;
  width: 80px;
  background-color: #e0f1fb;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.choose-box-img {
  height: 50px;
}
@media (max-width: 767px) {
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 33.333333%;
  }
}
@media (max-width: 767px) {
  .goal_detail_big{
    display:none !important
  }
}

@media (min-width: 767px) {
    .goal_detail_img_big{
      display:none !important
    }
  }
@media (max-width: 767px) {
    .big_screen_button{
        margin-top: 0px !important;
    }
  }